import { useEffect, useState } from "react"
import { useDebounce } from "use-debounce"

interface Props<S> {
  state: S
  setState: (newState: S) => void
  delay?: number
}

const DEFAULT_DELAY_MS = 350

const useBoundDebounceState = <S>(props: Props<S>) => {
  const stateHook = useState(props.state)
  const [state, setState] = stateHook

  const [debouncedState] = useDebounce(state, DEFAULT_DELAY_MS)

  useEffect(() => {
    if (props.state !== state) setState(props.state)
  }, [props.state])

  useEffect(() => {
    props.setState(debouncedState)
  }, [debouncedState])

  return stateHook
}

export default useBoundDebounceState
