import { Popover } from "@mui/material"
import { makeStyles } from "tss-react/mui-compat"
import { CalendarPicker } from "components/common/CalendarPicker"
import { DatePickerTriggerButton } from "components/common/DatePickerTriggerButton"
import { useBoolean } from "hooks/useBoolean"
import moment from "moment-timezone"
import React, { useRef } from "react"
import { pxToRem } from "utils/helpers"

const useStyles = makeStyles()(
  (theme) => ({
    popover: {
      overflowY: "hidden",
    },
    paper: {
      borderRadius: 10,
      border: `1px solid ${theme.palette.gray.darker}`,
      width: pxToRem(322),
      height: pxToRem(360),
    },
  })
)

interface DatePickerProps {
  selectedDate: moment.Moment | null
  onChange: (newSelectedDate: moment.Moment | null) => void
  /*
   * An array of available dates
   * If the array is empty, we allow the consumer to select any date they want
   * */
  availableDates?: moment.Moment[]
  label: string
  minDate?: moment.Moment
  maxDate?: moment.Moment
}

export const DatePicker = (props: DatePickerProps) => {
  const { selectedDate, onChange, availableDates, label, minDate, maxDate } = props
  const { classes } = useStyles()
  const triggerButtonRef = useRef<HTMLButtonElement | null>(null)

  const calendarOpen = useBoolean(false)

  const handleChange = (newDate: moment.Moment | null) => {
    calendarOpen.off()
    onChange(newDate)
  }

  return (
    <>
      <DatePickerTriggerButton
        label={label}
        ref={triggerButtonRef}
        onClick={calendarOpen.on}
        date={selectedDate}
      />
      <Popover
        disablePortal
        disableAutoFocus
        aria-hidden={!calendarOpen.value}
        open={calendarOpen.value}
        anchorEl={triggerButtonRef.current}
        onClose={calendarOpen.off}
        PaperProps={{ tabIndex: 1 }}
        classes={{
          root: classes.popover,
          paper: classes.paper,
        }}>
        <CalendarPicker
          autoFocus
          minDate={minDate}
          maxDate={maxDate}
          selectedDate={selectedDate}
          onChange={handleChange}
          availableDates={availableDates}
        />
      </Popover>
    </>
  )
}
