import { ConventionRegistrationState } from "app-constants/constants"
import ConventionInPersonCard from "components/conventions/ConventionInPersonCard"
import useActiveTicketType from "hooks/conventions/useActiveTicketType"
import useConventionPeopleCount from "hooks/conventions/useConventionPeopleCount"
import useConventionRegistrationState from "hooks/conventions/useConventionRegistrationState"
import useConventionsRegistrations from "hooks/conventions/useConventionsRegistrations"
import useIsConventionClosedForRegistration from "hooks/conventions/useIsConventionClosedForRegistration"
import useSearchParams from "hooks/useSearchParams"
import isRegistrationPaid from "lib/conventions/isRegistrationPaid"
import Convention from "models/convention-api/v1/Convention"
import React from "react"
import useAsyncEffect from "use-async-effect"

interface ConventionInPersonCardContainerProps {
  convention: Convention
  conventionRegistrations: ReturnType<typeof useConventionsRegistrations>
  href: string
  Card: typeof ConventionInPersonCard
}

const ConventionInPersonCardContainer = (props: ConventionInPersonCardContainerProps) => {
  const { convention, conventionRegistrations, href, Card } = props
  const searchParams = useSearchParams()
  const conventionIdToRegister = searchParams.get("conventionIdToRegister")
  const conventionRegistration = conventionRegistrations.getRegistration(convention)

  const conventionPeopleCount = useConventionPeopleCount(convention)
  const isClosedForRegistration = useIsConventionClosedForRegistration(convention)
  const activeTicketType = useActiveTicketType(convention, conventionRegistration)
  const registrationPaid = isRegistrationPaid(conventionRegistration)

  const registrationState = useConventionRegistrationState({
    isClosedForRegistration,
    convention,
    conventionRegistration,
    conventionPeopleCount,
    isLoadingConventionRegistration: conventionRegistrations.isConventionLoading(convention),
    activeTicketType,
    paymentStatus: conventionRegistration?.payment_intent?.status ?? null,
  })

  const onCancel = () => conventionRegistrations.cancel(convention)
  const onRegister = () => conventionRegistrations.register(convention, conventionPeopleCount)

  useAsyncEffect(async () => {
    if (registrationState.state !== ConventionRegistrationState.READY_FOR_REGISTRATION) return
    if (convention.id !== conventionIdToRegister) return

    await searchParams.delete("conventionIdToRegister")
    await onRegister()
  }, [registrationState.state, conventionIdToRegister])

  return (
    <Card
      convention={convention}
      registrationState={registrationState.state}
      activeTicketType={registrationPaid ? null : activeTicketType}
      onRegister={onRegister}
      onCancel={onCancel}
      href={href}
    />
  )
}

export default ConventionInPersonCardContainer
