import { Box, styled } from "@mui/material"
import { pxToRem } from "utils/helpers"

enum ConventionCardLayoutContainerItem {
  CONTENT = "CONTENT",
  IMAGE = "IMAGE",
  ACTIONS = "ACTIONS",
  SESSIONS = 'SESSIONS'
}

export const ConventionCardLayoutContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: `1fr`,
  gridTemplateAreas: `
    '${ConventionCardLayoutContainerItem.IMAGE}'
    '${ConventionCardLayoutContainerItem.CONTENT}'
    '${ConventionCardLayoutContainerItem.ACTIONS}'
    '${ConventionCardLayoutContainerItem.SESSIONS}'
  `,

  width: "100%",
  borderRadius: pxToRem(10),

  border: `${pxToRem(1)} solid ${theme.palette.gray.light}`,
  boxShadow: `0 ${pxToRem(2)} ${pxToRem(4)} rgba(0, 0, 0, 0.1)`,
  overflow: "hidden",

  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: `minmax(max-content, ${pxToRem(270)}) 1fr`,
    gridTemplateAreas: `
    '${ConventionCardLayoutContainerItem.IMAGE} ${ConventionCardLayoutContainerItem.CONTENT} '
    '${ConventionCardLayoutContainerItem.ACTIONS} ${ConventionCardLayoutContainerItem.ACTIONS}'
    '${ConventionCardLayoutContainerItem.SESSIONS} ${ConventionCardLayoutContainerItem.SESSIONS}'
  `,
  },
}))

export default ConventionCardLayoutContainer

export const ConventionCardLayoutContent = styled(Box)(({ theme }) => ({
  gridArea: ConventionCardLayoutContainerItem.CONTENT,

  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(14),
  padding: `${pxToRem(20)} ${pxToRem(20)} ${pxToRem(30)} ${pxToRem(20)}`,
  [theme.breakpoints.up("md")]: {
    padding: pxToRem(30),
  },
})) as typeof Box

export const ConventionCardLayoutContentDetails = styled(Box)(() => ({
  display: "flex",
  flexFlow: "row wrap",
  gap: `${pxToRem(10)}`,
  alignItems: "center",
  paddingTop: pxToRem(6),
})) as typeof Box

export const ConventionCardLayoutImage = styled(Box)(() => ({
  gridArea: ConventionCardLayoutContainerItem.IMAGE,

  display: "flex",
})) as typeof Box

export const ConventionCardLayoutActions = styled(Box)(({ theme }) => ({
  gridArea: ConventionCardLayoutContainerItem.ACTIONS,

  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: pxToRem(15),
  padding: `${pxToRem(25)} ${pxToRem(30)}`,
  borderTop: `${pxToRem(1)} solid ${theme.palette.gray.light}`,
})) as typeof Box

export const ConventionCardLayoutSessions = styled(Box)(() => ({
  gridArea: ConventionCardLayoutContainerItem.SESSIONS
})) as typeof Box
