import { LoadingButton, LoadingButtonProps } from "@mui/lab"
import { Box, styled, Typography } from "@mui/material"
import { VideoPlatform } from "app-constants/constants"
import { createPath, ROUTE } from "app-constants/routing"
import { SessionCardAction, SessionCardStatus } from "components/sessions/SessionCard"
import useConventionSessionRealTimeCapacity from "hooks/sessions/useConventionSessionRealTimeCapacity"
import useConventionSessionsRegistrations from "hooks/sessions/useConventionSessionsRegistrations"
import useSessionCopyText from "hooks/sessions/useSessionCopyText"
import useRelativeTime from "hooks/useRelativeTime"
import useSearchParams from "hooks/useSearchParams"
import Convention from "models/convention-api/v1/Convention"
import ConventionSession from "models/convention-api/v1/ConventionSession"
import Link from "next/link"
import pluralize from "pluralize"
import React from "react"
import { isEmbed, pxToRem } from "utils/helpers"

const MOMENT_FORMAT = "MMMM D, YYYY, h:mm A z"

interface ConventionSessionsListItemProps {
  convention: Convention
  session: ConventionSession
  conventionSessionsRegistrations: ReturnType<typeof useConventionSessionsRegistrations>
}

const ConventionSessionsListItem = (props: ConventionSessionsListItemProps) => {
  const { convention, session, conventionSessionsRegistrations } = props

  const sessionCopyText = useSessionCopyText()
  const searchParams = useSearchParams()

  const conventionSessionCapacity = useConventionSessionRealTimeCapacity(session)

  const relativeTime = useRelativeTime({
    startAt: session.start_at,
    endAt: session.end_at,
  })

  const isLive = relativeTime.during

  const getCapacityStatusText = () => {
    if (!conventionSessionCapacity) return undefined
    const { zoomSeatsLeft, wherebyTotalInRoom } = conventionSessionCapacity

    if (session.video_platform === VideoPlatform.WHEREBY) {
      return `${wherebyTotalInRoom} ${pluralize("people", wherebyTotalInRoom)} in the room`
    }

    const MIN_VALUE_TO_SHOW_SEATS_STATUS = 20

    if (zoomSeatsLeft < MIN_VALUE_TO_SHOW_SEATS_STATUS) return undefined

    return `Only ${zoomSeatsLeft} ${pluralize("seat", zoomSeatsLeft)} left. `
  }

  const action = ((): SessionCardAction => {
    if (conventionSessionsRegistrations.isRegistering(session.id))
      return {
        status: SessionCardStatus.LOADING,
      }

    if (conventionSessionCapacity?.full)
      return {
        status: SessionCardStatus.AT_CAPACITY,
      }

    if (isLive)
      return {
        status: SessionCardStatus.LIVE,
        statusText: getCapacityStatusText(),
        onClick: async () => {
          await searchParams.setMultiple({
            sessionConventionId: convention.id,
            sessionIdToJoin: session.id,
          })
        },
      }

    if (conventionSessionsRegistrations.isRegistered(session.id))
      return {
        status: SessionCardStatus.REGISTERED,
        onClick: async () => {
          await searchParams.setMultiple({
            sessionConventionId: convention.id,
            sessionIdToRemoveReminder: session.id,
          })
        },
      }

    return {
      status: SessionCardStatus.REGISTRATION,
      onClick: async () => {
        await searchParams.setMultiple({
          sessionConventionId: convention.id,
          sessionIdToRemind: session.id,
        })
      },
    }
  })()

  const actionStatusText = (() => {
    if (action.status === SessionCardStatus.LIVE) return action.statusText
    if (action.status === SessionCardStatus.AT_CAPACITY)
      return sessionCopyText?.singular
        ? `This ${sessionCopyText?.singular} is at capacity`
        : "At capacity"
    return undefined
  })()
  const actionText = {
    [SessionCardStatus.LIVE]: "Join",
    [SessionCardStatus.REGISTERED]: "Cancel Registration",
    [SessionCardStatus.LOADING]: "Loading...",
    [SessionCardStatus.REGISTRATION]: "Register",
  }[action.status]

  const actionLoading = [SessionCardStatus.AT_CAPACITY, SessionCardStatus.LOADING].includes(
    action.status,
  )

  const handleActionClick: LoadingButtonProps["onClick"] = (event) => {
    if ("onClick" in action) action?.onClick(event)
  }

  return (
    <_Container>
      <Typography
        color="gray.darker"
        variant="paragraphSmall"
        component="time"
        dateTime={session.start_at.format()}>
        {session.start_at.format(MOMENT_FORMAT)}
      </Typography>
      <_MiddleInformationContainer>
        <Link
          href={createPath({
            path: isEmbed() ? ROUTE.EMBED_CONVENTION_SESSION : ROUTE.CONVENTION_SESSION,
            params: {
              conventionId: convention.id,
              sessionId: session.id,
            },
          })}
          passHref legacyBehavior>
          <Typography
            color="gray.darker"
            variant="paragraphBold"
            role="heading"
            component="a"
            sx={{ cursor: "pointer" }}>
            {session.name}
          </Typography>
        </Link>
        <Typography color="gray.darker" variant="paragraphSmall" fontSize={pxToRem(12)}>
          {session.convention_exhibitor?.exhibitor_name}
        </Typography>
      </_MiddleInformationContainer>
      <_RegistrationContainer>
        {actionText && (
          <LoadingButton loading={actionLoading} variant="text" onClick={handleActionClick}>
            {actionText}
          </LoadingButton>
        )}
        {actionStatusText && <Typography variant="paragraphItalic">{actionStatusText}</Typography>}
      </_RegistrationContainer>
    </_Container>
  )
}

export default ConventionSessionsListItem

const _Container = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: pxToRem(20),
  gridTemplateColumns: "1fr",
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "1fr 1fr 1fr",
  },
  minHeight: pxToRem(89),
  padding: `${pxToRem(25)} ${pxToRem(29)}`,
}))

const _MiddleInformationContainer = styled(Box)(() => ({
  display: "flex",
  gap: pxToRem(5),
  flexFlow: "column nowrap",
}))

const _RegistrationContainer = styled(Box)(() => ({
  display: "flex",
  gap: pxToRem(5),
  flexFlow: "column nowrap",
}))
