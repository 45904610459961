import { createPath, ROUTE } from "app-constants/routing"
import useConventionsRegistrations from "hooks/conventions/useConventionsRegistrations"
import useSearchParams from "hooks/useSearchParams"
import { api } from "lib/api"
import deleteConventionSessionRegistration from "lib/deleteConventionSessionRegistration"
import useCurrent from "lib/use-current"
import ConventionSession from "models/convention-api/v1/ConventionSession"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import useAsyncEffect from "use-async-effect"
import { assertCurrent } from "utils/asserts"
import { isEmbed } from "utils/helpers"

interface userConventionSessionsRegistrationsProps {
  conventionRegistrations: ReturnType<typeof useConventionsRegistrations>
}

const useConventionSessionsRegistrations = (props: userConventionSessionsRegistrationsProps) => {
  const { conventionRegistrations } = props
  const [registeredSessionsIds, setRegisteredSessionsIds] = useState<Record<string, boolean>>({})
  const [registrationSessionsIds, setRegisteringSessionsIds] = useState<Record<string, boolean>>({})

  const router = useRouter()

  const searchParams = useSearchParams()
  const sessionIdToRemind = searchParams.get("sessionIdToRemind")
  const sessionIdToRemoveReminder = searchParams.get("sessionIdToRemoveReminder")
  const sessionIdToJoin = searchParams.get("sessionIdToJoin")
  const sessionConventionId = searchParams.get("sessionConventionId")

  const sessionConventionRegistered =
    conventionRegistrations.isConventionRegisteredById(sessionConventionId)
  const current = useCurrent()
  const userId = current?.user?.id

  const userSessionsModel = ConventionSession.useAll(() => {
    if (!userId) return null

    return {
      filter: {
        convention_user_id: userId,
      },
    }
  })

  const setSessionRegistered = (sessionId: string, registered: boolean) =>
    setRegisteredSessionsIds((previousState) => ({ ...previousState, [sessionId]: registered }))

  const setSessionRegistering = (sessionId: string, registering: boolean) =>
    setRegisteringSessionsIds((previousState) => ({ ...previousState, [sessionId]: registering }))

  const registerSession = async (sessionId: string) => {
    setSessionRegistering(sessionId, true)

    try {
      await api.registerForSession({ req: undefined }, sessionId)
      setSessionRegistered(sessionId, true)
    } catch (error) {
      console.error(error)
      setSessionRegistered(sessionId, false)
    } finally {
      setSessionRegistering(sessionId, false)
    }
  }

  const unregisterSession = async (sessionId: string) => {
    setSessionRegistering(sessionId, true)

    try {
      assertCurrent(current)

      await deleteConventionSessionRegistration({ current, sessionId })
      setSessionRegistered(sessionId, false)
    } catch (error) {
      console.error(error)
      setSessionRegistered(sessionId, true)
    } finally {
      setSessionRegistering(sessionId, false)
    }
  }

  useEffect(() => {
    if (!userSessionsModel.data) return

    setRegisteredSessionsIds(
      (userSessionsModel.data ?? []).reduce<Record<string, boolean>>((store, currentSession) => {
        store[currentSession.id] = true
        return store
      }, {}),
    )
  }, [userSessionsModel.data])

  const loading = !userSessionsModel.data && userId

  const isRegistered = (sessionId: string) => registeredSessionsIds[sessionId] ?? false
  const isRegistering = (sessionId: string) =>
    Boolean(registrationSessionsIds[sessionId] || loading)

  useAsyncEffect(async () => {
    if (!sessionConventionId) return

    if (!sessionConventionRegistered && sessionConventionId) {
      await searchParams.set("conventionIdToRegister", sessionConventionId)
      return
    }

    if (!sessionIdToJoin && !sessionIdToRemind && !sessionIdToRemoveReminder) return

    if (sessionIdToRemind) {
      await registerSession(sessionIdToRemind)
      await searchParams.delete("sessionIdToRemind")
      await searchParams.delete("sessionConventionId")
    }

    if (sessionIdToRemoveReminder) {
      await unregisterSession(sessionIdToRemoveReminder)
      await searchParams.delete("sessionIdToRemoveReminder")
      await searchParams.delete("sessionConventionId")
    }

    if (sessionIdToJoin) {
      await searchParams.delete("sessionIdToJoin")
      await searchParams.delete("sessionConventionId")
      await router.push(
        createPath({
          path: isEmbed() ? ROUTE.EMBED_CONVENTION_SESSION : ROUTE.CONVENTION_SESSION,
          params: {
            conventionId: sessionConventionId ?? (router.query.convention_id as string),
            sessionId: sessionIdToJoin,
          },
          query: {
            sessionIdToJoin: sessionIdToJoin,
          },
        }),
      )
      return
    }
  }, [
    sessionIdToRemind,
    sessionIdToRemoveReminder,
    sessionIdToJoin,
    sessionConventionRegistered,
    sessionConventionId,
  ])

  return {
    isRegistered,
    isRegistering,
    registerSession,
    unregisterSession,
  }
}

export default useConventionSessionsRegistrations
