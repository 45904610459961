import logoImage from "/public/assets/images/powered-by-visitdays-logo-blue.svg"
import { tss } from "tss-react"
import Image from "next/image"

interface IPoweredByVisitdaysLogo {}

const PoweredByVisitdaysLogo = (_props: IPoweredByVisitdaysLogo) => {
  const { classes } = useStyles()

  return (
    <div className={classes.root} role="region" aria-label="Footer">
      <a href="https://www.visitdays.com" rel="noreferrer" target="_blank" className={classes.link}>
        <Image src={logoImage} alt="Powered by VisitDays" className={classes.image} />
      </a>
    </div>
  )
}

export default PoweredByVisitdaysLogo

const useStyles = tss
  .withName({ PoweredByVisitdaysLogo })
  .create({
    root: {
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      marginBottom: "2rem",
      marginTop: "2rem",
      width: "100%",
      fontSize: "1.25rem",
      fontWeight: "bold",
      textAlign: "center",
      color: "#113b5b",
    },
    link: {
      display: "block",
      textDecoration: "none",
    },
    image: {
      maxWidth: "100%",
    },
  });