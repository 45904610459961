import LiveTvOutlinedIcon from "@mui/icons-material/LiveTvOutlined"
import { styled, Typography } from "@mui/material"
import getConventionLocationStyleLabel from "lib/conventions/getConventionLocationStyleLabel"
import useCurrent from "lib/use-current"
import Convention from "models/convention-api/v1/Convention"
import React from "react"
import { pxToRem } from "utils/helpers"

interface ConventionCardTypeNameProps {
  convention: Convention
}

const ConventionCardTypeName = (props: ConventionCardTypeNameProps) => {
  const { convention } = props
  const current = useCurrent()

  return (
    <_Typography>
      <LiveTvOutlinedIcon />
      <span>{getConventionLocationStyleLabel(convention, current!)}</span>
    </_Typography>
  )
}

export default ConventionCardTypeName

const _Typography = styled(Typography)(({ theme }) => ({
  ...theme.typography.paragraphSmall,
  display: "flex",
  alignItems: "center",
  gap: pxToRem(10),
  textTransform: "capitalize",
  color: theme.palette.gray.darker,
})) as typeof Typography
