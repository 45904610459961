import { Box, Button, ButtonProps, styled } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import { ConventionRegistrationState } from "app-constants/constants"
import Convention from "models/convention-api/v1/Convention"
import React from "react"

interface ConventionCardRegistrationProps {
  convention: Convention
  onRegister: () => void
  onCancel: () => void
  registerButtonLabel: string
  cancelButtonLabel: string
  buttonVariant: ButtonProps["variant"]
  registrationState: ConventionRegistrationState
}

const ConventionCardRegistration = (props: ConventionCardRegistrationProps) => {
  const {
    convention,
    onRegister,
    onCancel,
    registerButtonLabel,
    cancelButtonLabel,
    registrationState,
    buttonVariant,
  } = props

  return (
    <_Container>
      {registrationState === ConventionRegistrationState.LOADING && (
        <CircularProgress
          aria-label={`Loading the registration status of ${convention.name}`}
          variant="indeterminate"
          size={18}
        />
      )}
      {registrationState === ConventionRegistrationState.REGISTERED && (
        <Button
          aria-label={`Cancel the registration for ${convention.name}`}
          onClick={onCancel}
          variant={buttonVariant}>
          {cancelButtonLabel}
        </Button>
      )}

      {(registrationState === ConventionRegistrationState.READY_FOR_REGISTRATION ||
        registrationState === ConventionRegistrationState.FEW_SPOTS_LEFT) && (
        <Button
          aria-label={`Register for ${convention.name}`}
          onClick={onRegister}
          variant={buttonVariant}>
          {registerButtonLabel}
        </Button>
      )}
    </_Container>
  )
}

export default ConventionCardRegistration

const _Container = styled(Box)(({}) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
})) as typeof Box
