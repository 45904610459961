import { Box, CardMedia, styled } from "@mui/material"
import AspectRatio from "components/common/AspectRatio"
import { FallbackImage } from "components/common/FallbackImage"
import Convention from "models/convention-api/v1/Convention"
import Link from "next/link"
import React, { useState } from "react"
import { pxToRem } from "utils/helpers"

interface ConventionCardImageProps {
  convention: Convention
  href: string
}

const ConventionCardImage = (props: ConventionCardImageProps) => {
  const { convention, href } = props
  const [shouldShowFallbackImage, setShouldShowFallbackImage] = useState(false)

  const imageSrc = convention.logo_url ?? convention.hero_image_url
  const imageAlt = `${convention.name}'s image`
  const onCardMediaError = () => {
    if (shouldShowFallbackImage) return

    console.error(
      `${convention.name} convention has failed to load the cover image thumbnail`,
      imageSrc,
    )
    setShouldShowFallbackImage(true)
  }

  return (
    <_Container>
      <CustomBackgroundImage aria-hidden="true" src={imageSrc} />
      <AspectRatio ratio={16 / 9}>
        {shouldShowFallbackImage || !imageSrc ? (
          <FallbackImage ariaLabel="Exhibitor default image" />
        ) : (
          <CardMedia
            onError={onCardMediaError}
            component="img"
            image={imageSrc}
            alt={imageAlt}
            aria-hidden={!imageAlt}
          />
        )}
        <Link href={href} passHref legacyBehavior>
          <CustomImageLink aria-label={`Open ${convention.name}`} />
        </Link>
      </AspectRatio>
    </_Container>
  )
}

export default ConventionCardImage

const _Container = styled(Box)(() => ({
  position: "relative",

  display: "flex",
  justifyContent: "stretch",
  alignItems: "center",
  alignContent: "stretch",

  width: "100%",
  height: "100%",

  overflow: "hidden",

  "& > *": {
    flex: 1,
  },
})) as typeof Box

const CustomImageLink = styled("a")(({}) => ({
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,

  zIndex: 1,
  cursor: "pointer",
}))

const CustomBackgroundImage = styled("img")(() => ({
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: "100%",
  height: "100%",
  filter: `blur(${pxToRem(10)})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
}))
