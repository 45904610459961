import { ConventionLocationStyle } from "app-constants/constants"
import { Filter } from "models/convention-api/types"
import { Moment } from "moment"
import { useMemo, useState } from "react"
import { useDebounce } from "use-debounce"

export interface ConventionFilters {
  name: string
  startAt: null | Moment
  endAt: null | Moment
  locationStyles: ConventionLocationStyle[]
}
const useConventionFilters = () => {
  const [name, setName] = useState("")
  const [startAt, setStartAt] = useState<Moment | null>(null)
  const [endAt, setEndAt] = useState<Moment | null>(null)
  const [locationStyles, setLocationStyles] = useState<ConventionLocationStyle[]>([])

  const reset = () => {
    setName("")
    setStartAt(null)
    setEndAt(null)
    setLocationStyles([])
  }

  const toggleLocationStyle = (locationStyle: ConventionLocationStyle) => {
    const isChecked = locationStyles.includes(locationStyle)
    setLocationStyles((prevLocationStyles) =>
      isChecked
        ? prevLocationStyles.filter((prevLocationStyle) => prevLocationStyle !== locationStyle)
        : [...prevLocationStyles, locationStyle],
    )
  }

  const filter: Filter = useMemo(() => {
    const filters: Filter = {}
    if (name.length > 0) filters.name = name
    if (startAt) {
      filters.start_at_gteq = startAt.startOf("day").toISOString()
    }

    if (endAt) {
      filters.end_at_lteq = endAt.clone().add("day", 1).startOf("day").toISOString()
    }

    if (locationStyles.length > 0) filters.location_style = locationStyles
    return filters
  }, [name, startAt, endAt, locationStyles])
  const [debouncedFilter] = useDebounce(filter, 500)

  const isFilterApplied =
    name.length !== 0 || startAt !== null || endAt !== null || locationStyles.length !== 0

  return {
    name,
    setName,

    startAt,
    setStartAt,

    endAt,
    setEndAt,

    locationStyles,
    toggleLocationStyle,
    setLocationStyles,

    filter: debouncedFilter,
    isFilterApplied,
    reset,
  }
}

export default useConventionFilters
