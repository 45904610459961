import Convention from 'models/convention-api/v1/Convention';
import ConventionSession from 'models/convention-api/v1/ConventionSession';
import {Box, styled} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {pxToRem} from 'utils/helpers';
import React from 'react';
import Button from '@mui/material/Button';
import ConventionSessionsListItem from 'components/conventions/ConventionSessionsListItem';
import useConventionSessionsRegistrations from 'hooks/sessions/useConventionSessionsRegistrations';


interface ConventionSessionsCardProps {
  convention: Convention
  conventionSessionsRegistrations: ReturnType<typeof useConventionSessionsRegistrations>
}

const ConventionSessionsList = (props: ConventionSessionsCardProps) => {
  const {convention, conventionSessionsRegistrations} = props

  const sessions = ConventionSession.useMore(() => {
    return {
      limit: 5,
      filter: {
        convention_id: convention.id,
      },
      includes: ['convention_exhibitor'],
    }
  })


  // NOTE: sessions.hasMore doesn't work correctly for some reason.
  const loadMoreAvailable = convention.convention_session_count?.session_count > sessions.data?.length

  if (!sessions.data) {
    return (
      <_CircularProgressContainer>
        <CircularProgress
          variant="indeterminate"
          size={20}
          aria-label="Loading the list..."
        />
      </_CircularProgressContainer>
    )
  }

  return (
    <_List>
      {sessions.data?.map((session) => (
        <_ListItem key={session.id}>
          <ConventionSessionsListItem
            convention={convention}
            session={session}
            conventionSessionsRegistrations={conventionSessionsRegistrations}
          />
        </_ListItem>
      ))}
      {loadMoreAvailable && (
        <_ListItem>
          <_ListItemLoader>
            {sessions.isLoading || !sessions.data ? (
              <CircularProgress
                variant="indeterminate"
                size={20}
                aria-label="Please wait, we are loading more items for the list above."
              />
            ) : (
              <Button color="primary" onClick={sessions.loadMore}>load more</Button>
            )}
          </_ListItemLoader>
        </_ListItem>
      )}
    </_List>
  )
}

export default ConventionSessionsList

const _CircularProgressContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: pxToRem(108),
}))

const _List = styled('ul')(({theme}) => ({
  display: 'flex',
  justifyContent: 'stretch',
  flexFlow: 'column nowrap',
  margin: 0,
  padding: 0,
  listStyleType: 'none',
  "& > li+li": {
    borderTop: `1px solid ${theme.palette.gray.lighter}`
  },
}))

const _ListItem = styled('li')(() => ({}))

const _ListItemLoader = styled(_ListItem)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: pxToRem(108),
}))
