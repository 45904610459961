import { Box, styled } from "@mui/material"
import { ConventionLocationStyle } from "app-constants/constants"
import { createPath, ROUTE } from "app-constants/routing"
import ConventionInPersonCard from "components/conventions/ConventionInPersonCard"
import ConventionInPersonCardContainer from "components/conventions/ConventionInPersonCardContainer"
import ConventionVirtualCard from "components/conventions/ConventionVirtualCard"
import ConventionVirtualCardContainer from "components/conventions/ConventionVirtualCardContainer"
import useConventionsRegistrations from "hooks/conventions/useConventionsRegistrations"
import Convention from "models/convention-api/v1/Convention"
import React from "react"
import { pxToRem } from "utils/helpers"
import useConventionSessionsRegistrations from 'hooks/sessions/useConventionSessionsRegistrations';

interface ConventionsVerticalListProps {
  conventions: Convention[]
  detailPath?: ROUTE.CONVENTION | ROUTE.EMBED_CONVENTION
}

const ConventionsVerticalList = (props: ConventionsVerticalListProps) => {
  const { conventions, detailPath = ROUTE.CONVENTION } = props
  const conventionRegistrations = useConventionsRegistrations()
  const conventionSessionsRegistrations = useConventionSessionsRegistrations({
    conventionRegistrations
  })

  return (
    <>
      {conventionRegistrations.dialogs}
      <_Container>
        {conventions.map((convention) => {
          const href = createPath({
            path: detailPath,
            params: {
              conventionId: convention.id,
            },
          })
          if (convention.location_style === ConventionLocationStyle.VIRTUAL) {
            return (
              <ConventionVirtualCardContainer
                key={convention.id}
                convention={convention}
                conventionRegistrations={conventionRegistrations}
                conventionSessionsRegistrations={conventionSessionsRegistrations}
                href={href}
                Card={ConventionVirtualCard}
              />
            )
          }

          if (convention.location_style === ConventionLocationStyle.INPERSON) {
            return (
              <ConventionInPersonCardContainer
                key={convention.id}
                convention={convention}
                conventionRegistrations={conventionRegistrations}
                href={href}
                Card={ConventionInPersonCard}
              />
            )
          }

          return null
        })}
      </_Container>
    </>
  )
}

export default ConventionsVerticalList

const _Container = styled(Box)(({}) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: pxToRem(40),

  width: "100%",
  height: "100%",
}))
