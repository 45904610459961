import { Box, styled, Typography } from "@mui/material"
import { ConventionRegistrationState } from "app-constants/constants"
import Convention from "models/convention-api/v1/Convention"
import React from "react"

interface ConventionCardStatusProps {
  convention: Convention
  registrationState: ConventionRegistrationState
}

const ConventionCardStatus = (props: ConventionCardStatusProps) => {
  const { registrationState, convention } = props
  return (
    <_Container>
      {registrationState === ConventionRegistrationState.PROCESSING_PAYMENT && (
        <Typography
          aria-label={`Your payment for ${convention.name} is in process`}
          variant="paragraphItalic">
          Processing payment
        </Typography>
      )}

      {registrationState === ConventionRegistrationState.REGISTERED_PAID && (
        <Typography
          aria-label={`You're registered for ${convention.name}`}
          variant="paragraphItalic">
          Registered
        </Typography>
      )}

      {registrationState === ConventionRegistrationState.AT_CAPACITY && (
        <Typography
          aria-label={`No spots available for ${convention.name}`}
          variant="paragraphItalic">
          No spots available.
        </Typography>
      )}

      {registrationState === ConventionRegistrationState.CLOSED && (
        <Typography
          aria-label={`The registration is closed for ${convention.name}`}
          variant="paragraphItalic">
          Registration closed
        </Typography>
      )}
      {registrationState === ConventionRegistrationState.FEW_SPOTS_LEFT && (
        <Typography
          aria-label={`Only a few spots left for ${convention.name}!`}
          variant="paragraphItalic">
          Only a few spots left!
        </Typography>
      )}
    </_Container>
  )
}

export default ConventionCardStatus

const _Container = styled(Box)(({}) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
})) as typeof Box
