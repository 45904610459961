import AppLink from "components/navigation/AppLink"
import Convention from "models/convention-api/v1/Convention"
import React from "react"

interface ConventionCardViewDetailsProps {
  convention: Convention
  href: string
}

const ConventionCardViewDetails = (props: ConventionCardViewDetailsProps) => {
  const { convention, href } = props

  return (
    <AppLink href={href} aria-label={`Open ${convention.name}`}>
      View Detail
    </AppLink>
  )
}

export default ConventionCardViewDetails
