import CloseIcon from "@mui/icons-material/Close"
import { Box, Button, Dialog, styled, TextField, Typography } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import IconButton from "@mui/material/IconButton"
import { ConventionLocationStyle } from "app-constants/constants"
import { DatePicker } from "components/common/DatePicker"
import useConventionFilters from "hooks/conventions/useConventionFilters"
import useBoundDebounceState from "hooks/useBoundDebounceState"
import React from "react"
import { pxToRem } from "utils/helpers"

interface ConventionsFilterPanelDialogProps {
  onClose: () => void
  filters: ReturnType<typeof useConventionFilters>
  open: boolean
}

const ConventionsFilterPanelDialog = (props: ConventionsFilterPanelDialogProps) => {
  const { onClose, filters, open } = props

  // need this to improve typing performance
  const [name, setName] = useBoundDebounceState<string>({
    state: filters.name,
    setState: filters.setName,
  })

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault()
  }

  return (
    <_Dialog open={open}>
      <_Container component="form" onSubmit={handleSubmit}>
        <_HeaderContainer>
          <Typography component="h3" variant="header3">
            Filters
          </Typography>
          <IconButton color="dark" aria-label={`Close filters dialog`} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </_HeaderContainer>
        <_ActionsContainer>
          {filters.isFilterApplied && (
            <_DialogBottomButton
              color="dark"
              variant="text"
              sx={{ textTransform: "capitalize" }}
              onClick={filters.reset}>
              Clear Filters
            </_DialogBottomButton>
          )}
        </_ActionsContainer>
        <_ContentContainer>
          <_SectionContainer>
            <Typography variant="header4" component="h4">
              Search
            </Typography>
            <_SearchTextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              variant="filled"
              placeholder="Search by event name"
            />
          </_SectionContainer>
          <_SectionContainer>
            <Typography variant="header4" component="h4">
              Pick a date range
            </Typography>
            <_DatePickersContainer>
              <DatePicker
                selectedDate={filters.startAt}
                onChange={filters.setStartAt}
                label="Between"
                maxDate={filters.endAt ?? undefined}
              />
              <DatePicker
                selectedDate={filters.endAt}
                onChange={filters.setEndAt}
                label="And"
                minDate={filters.startAt ?? undefined}
              />
            </_DatePickersContainer>
          </_SectionContainer>
          <_SectionContainer>
            <FormControl component="fieldset">
              <Typography variant="header4" component="legend" marginBottom={pxToRem(15)}>
                Event Type
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={filters.locationStyles.includes(ConventionLocationStyle.INPERSON)}
                      onChange={() => filters.toggleLocationStyle(ConventionLocationStyle.INPERSON)}
                    />
                  }
                  label="In Person Events"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={filters.locationStyles.includes(ConventionLocationStyle.VIRTUAL)}
                      onChange={() => filters.toggleLocationStyle(ConventionLocationStyle.VIRTUAL)}
                    />
                  }
                  label="Virtual Events"
                />
              </FormGroup>
            </FormControl>
          </_SectionContainer>
        </_ContentContainer>
      </_Container>
    </_Dialog>
  )
}

export default ConventionsFilterPanelDialog

const _Dialog = styled(Dialog)(({ theme }) => ({
  "& > .MuiDialog-container > .MuiDialog-paper": {
    position: "relative",

    width: "100%",
    minWidth: pxToRem(300),
    maxWidth: pxToRem(360),

    borderRadius: pxToRem(10),

    backgroundColor: theme.palette.gray.lightest,
    boxShadow: `0 ${pxToRem(4)} ${pxToRem(4)} rgba(0, 0, 0, 0.25), 0 0 ${pxToRem(
      4,
    )} rgba(0, 0, 0, 0.12)`,

    overflowY: "hidden",
  },
})) as typeof Dialog

const _Container = styled(Box)(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(40),

  padding: pxToRem(20),
  paddingBottom: pxToRem(40),

  overflowY: "hidden",
})) as typeof Box

const _ContentContainer = styled(Box)(({}) => ({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(30),

  overflowY: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
  },
})) as typeof Box

const _ActionsContainer = styled(Box)(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  margin: pxToRem(-20),
  minHeight: pxToRem(32),
})) as typeof Box

const _SectionContainer = styled(Box)(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(15),
})) as typeof Box

const _DialogBottomButton = styled(Button)({
  width: "100%",
  borderRadius: 0,
  marginTop: 0,
})

const _HeaderContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexFlow: "nowrap row",
}) as typeof Box

const _SearchTextField = styled(TextField)(({}) => ({
  height: pxToRem(60),
  ".MuiFilledInput-input": {
    padding: `${pxToRem(19)} ${pxToRem(15)}`,
  },
})) as typeof TextField

const _DatePickersContainer = styled(Box)(({}) => ({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(20),
})) as typeof Box
