import { styled } from "@mui/material"
import { ConventionLocationStyle } from "app-constants/constants"
import Convention from "models/convention-api/v1/Convention"
import moment from "moment"
import React from "react"
import { pxToRem } from "utils/helpers"

interface ConventionTimeProps {
  convention: Convention
  className?: string
}

const MOMENT_FORMAT = "MMMM D, YYYY, h:mm A z"

const ConventionTime = (props: ConventionTimeProps) => {
  const { convention, className } = props
  const startAt = (() => {
    const startAt = moment(convention.start_at).clone()
    if (convention.location_style === ConventionLocationStyle.VIRTUAL) return startAt

    return startAt.tz(convention.time_zone)
  })()

  const endAt = (() => {
    const endAt = moment(convention.end_at).clone()
    if (convention.location_style === ConventionLocationStyle.VIRTUAL) return endAt

    return endAt.tz(convention.time_zone)
  })()

  const datetime = `${startAt.format()}/${endAt.format()}`
  const isSameDate = startAt.isSame(endAt, "day")

  return (
    <_Time dateTime={datetime} className={className}>
      <span>{startAt.format(MOMENT_FORMAT)}</span>
      {!isSameDate && <span>to</span>}
      {!isSameDate && <span>{endAt.format(MOMENT_FORMAT)}</span>}
    </_Time>
  )
}

export default ConventionTime

const _Time = styled("time")(({}) => ({
  display: "flex",
  flexFlow: "row wrap",
  gap: pxToRem(10),
}))
