import { ConventionRegistrationState } from "app-constants/constants"
import ConventionCardDateTime from "components/conventions/ConventionCardDateTime"
import ConventionCardImage from "components/conventions/ConventionCardImage"
import {
  ConventionCardLayoutActions,
  ConventionCardLayoutContainer,
  ConventionCardLayoutContent,
  ConventionCardLayoutContentDetails,
  ConventionCardLayoutImage,
} from "components/conventions/ConventionCardLayout"
import ConventionCardLocation from "components/conventions/ConventionCardLocation"
import ConventionCardName from "components/conventions/ConventionCardName"
import ConventionCardPrice from "components/conventions/ConventionCardPrice"
import ConventionCardRegistration from "components/conventions/ConventionCardRegistration"
import ConventionCardStatus from "components/conventions/ConventionCardStatus"
import ConventionCardViewDetails from "components/conventions/ConventionCardViewDetails"
import Convention from "models/convention-api/v1/Convention"
import ConventionTicketType from "models/convention-api/v1/ConventionTicketType"
import React from "react"

interface ConventionInPersonCardProps {
  // State
  convention: Convention
  registrationState: ConventionRegistrationState
  activeTicketType: null | ConventionTicketType
  href: string

  // Actions
  onCancel: () => void
  onRegister: () => void
}

const ConventionInPersonCard = (props: ConventionInPersonCardProps) => {
  const {
    // State
    convention,
    registrationState,
    activeTicketType,
    href,

    // Actions
    onCancel,
    onRegister,
  } = props

  return (
    <ConventionCardLayoutContainer>
      <ConventionCardLayoutContent>
        <ConventionCardName convention={convention} href={href} />
        <ConventionCardDateTime convention={convention} />
        <ConventionCardLayoutContentDetails>
          <ConventionCardLocation convention={convention} />
          <ConventionCardPrice activeTicketType={activeTicketType} />
        </ConventionCardLayoutContentDetails>
      </ConventionCardLayoutContent>
      <ConventionCardLayoutImage>
        <ConventionCardImage convention={convention} href={href} />
      </ConventionCardLayoutImage>
      <ConventionCardLayoutActions>
        <ConventionCardRegistration
          convention={convention}
          onCancel={onCancel}
          onRegister={onRegister}
          buttonVariant="outlined"
          registrationState={registrationState}
          registerButtonLabel="Register"
          cancelButtonLabel="Cancel Registration"
        />
        <ConventionCardViewDetails convention={convention} href={href} />
        <ConventionCardStatus convention={convention} registrationState={registrationState} />
      </ConventionCardLayoutActions>
    </ConventionCardLayoutContainer>
  )
}

export default ConventionInPersonCard
