import { Box, Typography } from "@mui/material"
import { styled } from "@mui/system"
import { NAVIGATION_HEIGHT } from "app-constants/constants"
import { ContentContainer } from "components/content-container"
import MainLayout from "components/main-layout"
import useCurrent from "lib/use-current"
import pluralize from "pluralize"
import React from "react"
import { pxToRem } from "utils/helpers"

interface NoConventionsYetFallbackProps {}

const NoConventionsYetFallback = (props: NoConventionsYetFallbackProps) => {
  const {} = props
  const current = useCurrent()

  const noExpoHeaderCopyText = current?.config.no_expo_header_copy_text
    ? current?.config.no_expo_header_copy_text
    : "Check back soon."

  const noExpoBodyCopyText = current?.config.no_expo_body_copy_text
    ? current?.config.no_expo_body_copy_text
    : `We're working hard on organizing our next ${pluralize(current?.config.expo_copy_text)}`

  return (
    <MainLayout layout="macro">
      <ContentContainer>
        <_Container>
          <_ContentContainer>
            <Typography component="h1" variant="header1">
              {noExpoHeaderCopyText}
            </Typography>
            <Typography component="p" variant="paragraphRegular" marginTop={pxToRem(20)}>
              {noExpoBodyCopyText}
            </Typography>
          </_ContentContainer>
        </_Container>
      </ContentContainer>
    </MainLayout>
  )
}

export default NoConventionsYetFallback

const CONTAINER_MARGIN_TOP_PX = pxToRem(64)
const CONTAINER_MARGIN_BOTTOM_PX = pxToRem(64)

const _Container = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  width: "100%",
  height: `
    calc(100vh - ${CONTAINER_MARGIN_TOP_PX} - ${CONTAINER_MARGIN_BOTTOM_PX} - ${NAVIGATION_HEIGHT}px)
  `,

  marginTop: CONTAINER_MARGIN_TOP_PX,
  marginBottom: CONTAINER_MARGIN_BOTTOM_PX,
  borderRadius: pxToRem(10),

  backgroundColor: theme.palette.primary.lighter,
})) as typeof Box

const _ContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "center",

  width: "100%",
  maxWidth: pxToRem(548),

  borderRadius: pxToRem(10),
  padding: pxToRem(40),
  background: theme.palette.gray.lightest,
  textAlign: "center",
})) as typeof Box
