import { ConventionRegistrationState } from "app-constants/constants"
import ConventionVirtualCard from "components/conventions/ConventionVirtualCard"
import useActiveTicketType from "hooks/conventions/useActiveTicketType"
import useConventionRegistrationState from "hooks/conventions/useConventionRegistrationState"
import useConventionsRegistrations from "hooks/conventions/useConventionsRegistrations"
import useIsConventionClosedForRegistration from "hooks/conventions/useIsConventionClosedForRegistration"
import useSearchParams from "hooks/useSearchParams"
import isRegistrationPaid from "lib/conventions/isRegistrationPaid"
import Convention from "models/convention-api/v1/Convention"
import React from "react"
import useAsyncEffect from "use-async-effect"
import useConventionSessionsRegistrations from 'hooks/sessions/useConventionSessionsRegistrations';

interface ConventionVirtualCardContainerProps {
  convention: Convention
  conventionRegistrations: ReturnType<typeof useConventionsRegistrations>
  conventionSessionsRegistrations: ReturnType<typeof useConventionSessionsRegistrations>
  href: string
  Card: typeof ConventionVirtualCard
}

const ConventionVirtualCardContainer = (props: ConventionVirtualCardContainerProps) => {
  const {
    convention,
    conventionRegistrations,
    conventionSessionsRegistrations,
    href,
    Card
  } = props
  const conventionRegistration = conventionRegistrations.getRegistration(convention)
  const searchParams = useSearchParams()
  const conventionIdToRegister = searchParams.get("conventionIdToRegister")

  const isClosedForRegistration = useIsConventionClosedForRegistration(convention)
  const activeTicketType = useActiveTicketType(convention, conventionRegistration)
  const registrationPaid = isRegistrationPaid(conventionRegistration)

  const registrationState = useConventionRegistrationState({
    isClosedForRegistration,
    convention,
    conventionRegistration,
    conventionPeopleCount: null,
    isLoadingConventionRegistration: conventionRegistrations.isConventionLoading(convention),
    activeTicketType,
    paymentStatus: conventionRegistration?.payment_intent?.status ?? null,
  })

  const onCancel = () => conventionRegistrations.cancel(convention)
  const onRegister = () => conventionRegistrations.register(convention)

  useAsyncEffect(async () => {
    if (registrationState.state !== ConventionRegistrationState.READY_FOR_REGISTRATION) return
    if (convention.id !== conventionIdToRegister) return

    await searchParams.delete("conventionIdToRegister")
    await onRegister()
  }, [registrationState.state, conventionIdToRegister])

  return (
    <Card
      conventionSessionsRegistrations={conventionSessionsRegistrations}
      activeTicketType={registrationPaid ? null : activeTicketType}
      convention={convention}
      registrationState={registrationState.state}
      onRegister={onRegister}
      onCancel={onCancel}
      href={href}
    />
  )
}

export default ConventionVirtualCardContainer
