import { StripePaymentStatus } from "app-constants/constants"
import ConventionRegistration from "models/convention-api/v1/ConventionRegistration"

const isRegistrationPaid = (conventionRegistration?: ConventionRegistration | null) => {
  if (!conventionRegistration) return false

  return conventionRegistration.payment_intent?.status === StripePaymentStatus.SUCCEEDED
}

export default isRegistrationPaid
