import Convention from 'models/convention-api/v1/Convention';
import {styled, Typography} from '@mui/material';
import {pxToRem} from 'utils/helpers';
import useSessionCopyText from 'hooks/sessions/useSessionCopyText';

interface ConventionSessionsCountProps {
  convention: Convention
}

const ConventionSessionsCount = (props: ConventionSessionsCountProps) => {
  const {convention} = props
  const sessionCopyText = useSessionCopyText()

  if (convention.automatically_generated) return null

  const sessionsCount = convention.convention_session_count?.session_count
  if (!(typeof sessionsCount === 'number')) return null

  return (
    <_Typography>
      <span aria-hidden="true">●</span>
      <span>{sessionsCount} {sessionCopyText?.byCount(sessionsCount)}</span>
    </_Typography>
  )
}

export default ConventionSessionsCount

const _Typography = styled(Typography)(({ theme }) => ({
  ...theme.typography.paragraphSmall,
  color: theme.palette.gray.darker,
  display: "flex",
  alignItems: "center",
  gap: pxToRem(10),
  textTransform: "capitalize",
})) as typeof Typography
