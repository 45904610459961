import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import { Box, styled } from "@mui/material"
import Convention from "models/convention-api/v1/Convention"
import React from "react"
import { pxToRem } from "utils/helpers"

interface ConventionCardLocationProps {
  convention: Convention
}

const ConventionCardLocation = (props: ConventionCardLocationProps) => {
  const { convention } = props

  return (
    <_Container>
      <LocationOnOutlinedIcon aria-hidden="true" />
      <span>{convention.location_name}</span>
      <span aria-hidden="true">●</span>
      <span>
        {convention.city}, {convention.state}
      </span>
    </_Container>
  )
}

export default ConventionCardLocation

const _Container = styled(Box)(({ theme }) => ({
  ...theme.typography.paragraphSmall,
  display: "flex",
  alignItems: "center",
  flexFlow: "row wrap",
  gap: pxToRem(10),
  color: theme.palette.gray.dark,
})) as typeof Box
