import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Sponsor from "models/convention-api/v1/Sponsor"
import SponsorTier from "models/convention-api/v1/SponsorTier"
import { makeStyles } from "tss-react/mui-compat"

interface ISponsorSort {
  id: string
  order: number
}

const useStyles = makeStyles<{
  alignment: "center" | "left"
}>()(
  (_theme, props) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      width: "100%",
    },
    wrapper: {
      width: "100%",
    },
    font: {
      width: "100%",
      color: "#556064",
      fontFamily: "Raleway",
      letterSpacing: 0,
      fontWeight: 500,
    },
    link: {
      display: "inline-block",
    },
    tier: {
      width: "90%",
      textAlign: props.alignment,
      margin: "auto",
    },
    logo: {
      maxHeight: "100%",
      maxWidth: "100%",
    },
    wrapperlargest: {
      marginBottom: "80px",
    },
    wrapperlarge: {
      marginBottom: "60px",
    },
    wrappermedium: {
      marginBottom: "40px",
    },
    wrappersmall: {
      marginBottom: "32px",
    },
    wrappersmallest: {
      marginBottom: "26px",
    },
    fontlargest: {
      fontSize: "36px",
      lineHeight: "42px",
      marginBottom: "40px",
    },
    fontlarge: {
      fontSize: "30px",
      lineHeight: "35px",
      marginBottom: "30px",
    },
    fontmedium: {
      fontSize: "24px",
      lineHeight: "29px",
      marginBottom: "20px",
    },
    fontsmall: {
      fontSize: "19px",
      lineHeight: "23px",
      marginBottom: "16px",
    },
    fontsmallest: {
      fontSize: "15px",
      lineHeight: "19px",
      marginBottom: "13px",
    },
    logolargest: {
      margin: "0 20px",
      height: "110px",
    },
    logolarge: {
      margin: "0 15px",
      height: "88px",
    },
    logomedium: {
      margin: "0 10px",
      height: "70px",
    },
    logosmall: {
      margin: "0 8px",
      height: "56px",
    },
    logosmallest: {
      margin: "0 6px",
      height: "45px",
    },
  })
)

interface ISponsorsList {
  sponsorTiers: SponsorTier[]
  sponsors: Sponsor[]
  alignment?: "center" | "left"
}

export const SponsorsList = (props: ISponsorsList) => {
  const { sponsorTiers, sponsors, alignment = "center" } = props

  const { classes, cx } = useStyles({
    alignment,
  })

  if (sponsorTiers.length === 0 || sponsors.length === 0) return null

  const sponsorSort = (a: ISponsorSort, b: ISponsorSort) => {
    if (a.order !== b.order) {
      return a.order - b.order
    } else {
      return +a.id - +b.id
    }
  }

  const orderedTiers = sponsorTiers.sort(sponsorSort)
  const orderedSponsors = sponsors.sort(sponsorSort)

  let renderedSponsors = []
  for (let i = 0; i < orderedTiers.length; i++) {
    const tierSponsors = orderedSponsors.filter(
      (s) => s.convention_sponsor_tier_id === orderedTiers[i].id,
    )
    renderedSponsors.push({
      // @ts-ignore
      size: orderedTiers[i].display_size,
      // @ts-ignore
      name: orderedTiers[i].name,
      // @ts-ignore
      sponsors: tierSponsors,
    })
  }

  return (
    <Box className={classes.root}>
      {renderedSponsors.map((t, i) => (
        <Box
          key={"sponsorTier" + i}
          // @ts-ignore
          className={cx(classes.wrapper, classes["wrapper" + t.size])}>
          {/*// @ts-ignore*/}
          {t.name && (
            <Typography
              align={alignment}
              // @ts-ignore
              className={cx(classes.font, classes["font" + t.size])}>
              {/*// @ts-ignore*/}
              {t.name}
            </Typography>
          )}
          <Box className={classes.tier}>
            {/*// @ts-ignore*/}
            {t.sponsors.map((s, j) => {
              const alt = s.name ? (s.name as string) : "Sponsor Logo"

              return (
                <a
                  key={"tier" + i + "sponsor" + j}
                  target="_blank"
                  href={s.url}
                  className={classes.link}
                  rel="noreferrer">
                  <img
                    src={s.logo_url}
                    // @ts-ignore
                    className={cx(classes.logo, classes["logo" + t.size])}
                    alt={alt}
                  />
                </a>
              )
            })}
          </Box>
        </Box>
      ))}
    </Box>
  )
}
