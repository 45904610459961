import { ConventionLocationStyle, ConventionRegistrationState } from "app-constants/constants"
import useCancelRegistrationDialog from "hooks/conventions/useCancelRegistrationDialog"
import useRegisterInPersonDialog from "hooks/conventions/useRegisterInPersonDialog"
import useRegisterVirtualDialog from "hooks/conventions/useRegisterVirtualDialog"
import { useUser } from "hooks/useUser"
import Convention from "models/convention-api/v1/Convention"
import ConventionRegistration from "models/convention-api/v1/ConventionRegistration"
import { useEffect, useMemo, useState } from "react"

interface ConventionRegistrationData {
  state: ConventionRegistrationState
  conventionRegistration: ConventionRegistration | null
}

const useConventionsRegistrations = () => {
  const user = useUser()

  const [conventionRegistrationsData, setConventionRegistrationsData] = useState<
    Record<string, ConventionRegistrationData>
  >({})

  const conventionRegistrations = ConventionRegistration.useAll(() => {
    if (!user?.id) return null

    return {
      filter: {
        upcoming: "true",
      },
      includes: ["ticket_type", "payment_intent"],
    }
  })

  const cancelRegistrationDialog = useCancelRegistrationDialog((payload) => {
    setSingleConventionRegistrationData(payload.convention, {
      state: ConventionRegistrationState.READY_FOR_REGISTRATION,
      conventionRegistration: null,
    })
  })

  const registerVirtualDialog = useRegisterVirtualDialog((payload) => {
    setSingleConventionRegistrationData(payload.convention, {
      state: ConventionRegistrationState.REGISTERED,
      conventionRegistration: payload.conventionRegistration,
    })
  })

  const registerInPersonDialog = useRegisterInPersonDialog((payload) => {
    setSingleConventionRegistrationData(payload.convention, {
      state: ConventionRegistrationState.REGISTERED,
      conventionRegistration: payload.conventionRegistration,
    })
  })

  const isLoading =
    conventionRegistrations.data === undefined && conventionRegistrations.isValidating

  useEffect(() => {
    if (conventionRegistrations.data) {
      setConventionRegistrationsData(
        conventionRegistrations.data.reduce<Record<string, ConventionRegistrationData>>(
          (state, conventionRegistration) => {
            state[conventionRegistration.convention_convention_id] = {
              state: ConventionRegistrationState.REGISTERED,
              conventionRegistration,
            }
            return state
          },
          {},
        ),
      )
    }
  }, [conventionRegistrations.data])

  const setSingleConventionRegistrationData = (
    convention: Convention,
    conventionRegistrationData: ConventionRegistrationData,
  ) => {
    setConventionRegistrationsData((prevState) => ({
      ...prevState,
      [convention.id]: conventionRegistrationData,
    }))
  }

  const register = async (convention: Convention, conventionPeopleCount?: null | number) => {
    if (convention.location_style === ConventionLocationStyle.VIRTUAL) {
      registerVirtualDialog.openDialog(convention)
    }

    if (convention.location_style === ConventionLocationStyle.INPERSON) {
      registerInPersonDialog.openDialog({
        convention,
        conventionPeopleCount: conventionPeopleCount ?? null,
      })
    }
  }

  const cancel = (convention: Convention) => {
    const conventionRegistration =
      conventionRegistrationsData[convention.id]?.conventionRegistration!

    cancelRegistrationDialog.openDialog({
      convention,
      conventionRegistration,
    })
  }

  const isConventionLoading = (convention: Convention) => {
    if (isLoading) return true
    const conventionRegistrationState = conventionRegistrationsData[convention.id]
    return conventionRegistrationState?.state === ConventionRegistrationState.LOADING
  }

  const isConventionRegistered = (convention: Convention) => {
    const conventionRegistrationState = conventionRegistrationsData[convention.id]
    return conventionRegistrationState?.state === ConventionRegistrationState.REGISTERED
  }

  const isConventionRegisteredById = (conventionId: string) => {
    const conventionRegistrationState = conventionRegistrationsData[conventionId]
    return conventionRegistrationState?.state === ConventionRegistrationState.REGISTERED
  }

  const hasRegistrations = useMemo(() => {
    return Object.values(conventionRegistrationsData).some(
      (registrationState) => registrationState?.state === ConventionRegistrationState.REGISTERED,
    )
  }, [conventionRegistrationsData])

  const getRegistration = (convention: Convention) => {
    return conventionRegistrationsData[convention.id]?.conventionRegistration
  }

  return {
    dialogs: (
      <>
        {cancelRegistrationDialog.Dialog}
        {registerVirtualDialog.Dialog}
        {registerInPersonDialog.Dialog}
      </>
    ),
    isConventionLoading,
    isConventionRegistered,
    isConventionRegisteredById,
    hasRegistrations,
    getRegistration,
    register,
    cancel,
  }
}

export default useConventionsRegistrations
