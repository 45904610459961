import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined"
import { styled, Typography } from "@mui/material"
import ConventionTicketType from "models/convention-api/v1/ConventionTicketType"
import React from "react"
import getFormattedPrice from "utils/getFormattedPrice"
import { pxToRem } from "utils/helpers"

interface ConventionCardPriceProps {
  activeTicketType: null | ConventionTicketType
}

const ConventionCardPrice = (props: ConventionCardPriceProps) => {
  const { activeTicketType } = props

  if (!activeTicketType) return <></>

  return (
    <_Typography>
      <PaidOutlinedIcon />
      <span>{getFormattedPrice(activeTicketType.cost_usd_cents)}</span>
    </_Typography>
  )
}

export default ConventionCardPrice

const _Typography = styled(Typography)(({ theme }) => ({
  ...theme.typography.paragraphSmall,
  display: "flex",
  alignItems: "center",
  gap: pxToRem(10),
  color: theme.palette.gray.darker,
})) as typeof Typography
