import {useBoolean} from 'usehooks-ts';
import IconButton from '@mui/material/IconButton';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import useSessionCopyText from 'hooks/sessions/useSessionCopyText';

interface ConventionSessionsButtonProps {
  expanded: ReturnType<typeof useBoolean>
}

const ConventionSessionsButton = (props: ConventionSessionsButtonProps) => {
  const {expanded} = props
  const sessionCopyText = useSessionCopyText()

  const ariaLabel = `${expanded.value ? "Expand" : "Collapse"} the list of ${sessionCopyText?.plural ?? 'sessions'}`

  return (
    <IconButton
      size="large"
      sx={{marginLeft: 'auto'}}
      aria-label={ariaLabel}
      onClick={expanded.toggle}
    >
      {expanded.value ? <ExpandLess />: <ExpandMore /> }
    </IconButton>
  )
}

export default ConventionSessionsButton
