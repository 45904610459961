import { ConventionRegistrationState } from "app-constants/constants"
import ConventionCardDateTime from "components/conventions/ConventionCardDateTime"
import ConventionCardImage from "components/conventions/ConventionCardImage"
import {
  ConventionCardLayoutContainer,
  ConventionCardLayoutActions,
  ConventionCardLayoutContent,
  ConventionCardLayoutImage,
  ConventionCardLayoutContentDetails, ConventionCardLayoutSessions,
} from 'components/conventions/ConventionCardLayout';
import ConventionCardName from "components/conventions/ConventionCardName"
import ConventionCardPrice from "components/conventions/ConventionCardPrice"
import ConventionCardRegistration from "components/conventions/ConventionCardRegistration"
import ConventionCardStatus from "components/conventions/ConventionCardStatus"
import ConventionCardTypeName from "components/conventions/ConventionCardTypeName"
import ConventionCardViewDetails from "components/conventions/ConventionCardViewDetails"
import Convention from "models/convention-api/v1/Convention"
import ConventionTicketType from "models/convention-api/v1/ConventionTicketType"
import React from "react"
import ConventionSessionsCount from 'components/conventions/ConventionSessionsCount';
import {useBoolean} from 'usehooks-ts';
import ConventionSessionsList from 'components/conventions/ConventionSessionsList';
import ConventionSessionsButton from 'components/conventions/ConventionSessionsButton';
import useConventionSessionsRegistrations from 'hooks/sessions/useConventionSessionsRegistrations';

interface ConventionVirtualCardProps {
  // State
  convention: Convention
  registrationState: ConventionRegistrationState
  conventionSessionsRegistrations: ReturnType<typeof useConventionSessionsRegistrations>
  activeTicketType: null | ConventionTicketType
  href: string

  // Actions
  onCancel: () => void
  onRegister: () => void
}

const ConventionVirtualCard = (props: ConventionVirtualCardProps) => {
  const {
    // State
    convention,
    registrationState,
    conventionSessionsRegistrations,
    activeTicketType,
    href,

    // Actions
    onCancel,
    onRegister,
  } = props
  const sessionsExpanded = useBoolean(false)
  const sessionsAvailable =
    !convention.automatically_generated &&
    convention.convention_session_count?.session_count > 0

  return (
    <ConventionCardLayoutContainer>
      <ConventionCardLayoutContent>
        <ConventionCardName convention={convention} href={href} />
        <ConventionCardDateTime convention={convention} />
        <ConventionCardLayoutContentDetails>
          <ConventionCardTypeName convention={convention} />
          <ConventionSessionsCount convention={convention} />
          <ConventionCardPrice activeTicketType={activeTicketType} />
        </ConventionCardLayoutContentDetails>
      </ConventionCardLayoutContent>
      <ConventionCardLayoutImage>
        <ConventionCardImage convention={convention} href={href} />
      </ConventionCardLayoutImage>
      <ConventionCardLayoutActions>
        <ConventionCardRegistration
          convention={convention}
          onCancel={onCancel}
          onRegister={onRegister}
          buttonVariant="outlined"
          registrationState={registrationState}
          registerButtonLabel="Register"
          cancelButtonLabel="Cancel Registration"
        />
        <ConventionCardViewDetails convention={convention} href={href} />
        <ConventionCardStatus convention={convention} registrationState={registrationState} />
        {sessionsAvailable && (
          <ConventionSessionsButton expanded={sessionsExpanded}/>
        )}
      </ConventionCardLayoutActions>
      {sessionsAvailable && sessionsExpanded.value && (
        <ConventionCardLayoutSessions>
          <ConventionSessionsList
            convention={convention}
            conventionSessionsRegistrations={conventionSessionsRegistrations}
          />
        </ConventionCardLayoutSessions>
      )}
    </ConventionCardLayoutContainer>
  )
}

export default ConventionVirtualCard
