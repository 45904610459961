import { styled } from "@mui/system"
import {
  DateCalendar as MUICalendarPicker,
  DateCalendarProps as MUICalendarPickerProps,
  PickersDay,
  PickersDayProps,
  DateView
} from "@mui/x-date-pickers"
import { Moment } from "moment"
import moment from "moment-timezone"
import React from "react"
import { pxToRem } from "utils/helpers"

const CustomCalendarPicker = styled(MUICalendarPicker)<MUICalendarPickerProps<moment.Moment>>(
  ({ theme }) => ({
    width: pxToRem(322),
    height: pxToRem(360),
    // Controls
    "& > div * .MuiSvgIcon-root": {
      color: theme.palette.gray.darkest,
    },
    // Days of the week
    ".MuiCalendarPicker-viewTransitionContainer > div:first-child > div:first-child > span": {
      opacity: 1,
      color: theme.palette.gray.dark,
    },
  }),
) as typeof MUICalendarPicker

type CustomPickerDayProps = PickersDayProps<moment.Moment> & {
  isAvailable?: boolean
}

const RenderDay = (props: CustomPickerDayProps) => {
  const { isAvailable = true, day, ...other } = props;
  return <PickersDay {...other} tabIndex={0} day={day} sx={{
    // Need this to center the text
    lineHeight: 0,
    pointerEvents: "none",

    ...(isAvailable && {
      pointerEvents: "auto",
      border: `1px solid`,

      "&.MuiPickersDay-today": {
        border: `1px solid`,
      },
    }),

    "&.MuiPickersDay-dayOutsideMonth": {
      opacity: 1,
      border: "none",
      cursor: "auto",
      pointerEvents: "none",
    },
  }} />
}

interface CalendarPickerProps {
  selectedDate: moment.Moment | null
  onChange: (newSelectedDate: moment.Moment | null) => void
  /*
   * An array of available dates
   * If the array is empty, we allow the consumer to select any date they want
   * */
  availableDates?: moment.Moment[]
  minDate?: moment.Moment
  maxDate?: moment.Moment
  autoFocus?: boolean
}

export const CalendarPicker = (props: CalendarPickerProps) => {
  const { selectedDate = null, onChange, availableDates = [], minDate, maxDate, autoFocus } = props

  const handleOnChange = (date: moment.Moment | null) => {
    // If availableDates is empty, we allow the consumer to select any date they want
    const hasSelectionRestrictions = availableDates.length > 0
    if (!hasSelectionRestrictions) {
      onChange(date)
      return
    }

    const isDateAvailable = availableDates.find((availableDate) =>
      availableDate.isSame(date, "day"),
    )

    if (isDateAvailable) {
      onChange(date)
      return
    }
  }

  const isDateAvailable = (date: Moment) => {
    if (minDate && minDate.isAfter(date, "day")) return false
    if (maxDate && maxDate.isBefore(date, "day")) return false
  
    if (availableDates.length === 0) return true
    return !!availableDates.find((currentDate) => currentDate.isSame(date, "day"))
  }

  const getViews = (): DateView[] | undefined => {
    // NOTE: Order matters
    if (minDate === undefined || maxDate === undefined) return ["day", "year", "month"]

    if (minDate.isSame(maxDate, "day")) return ["day"]
    if (minDate.isSame(maxDate, "month")) return ["day"]
    if (minDate.isSame(maxDate, "year")) return ["day", "month"]

    return ["day", "year", "month"]
  }

  return (
    <CustomCalendarPicker<moment.Moment>
      reduceAnimations
      autoFocus={autoFocus}
      views={getViews()}
      minDate={minDate}
      maxDate={maxDate}
      value={selectedDate}
      onChange={handleOnChange}
      slots={{
        day: RenderDay
      }}
      slotProps={{
        day: (ownerState) =>
        ({
          selectedDay: selectedDate,
          isAvailable: isDateAvailable(ownerState.day),
        }) as any,

      }}
    />
  )
}
