import { ConventionLocationStyleLabel } from "app-constants/constants"
import getExpoCopyText from "lib/getExpoCopyText"
import { Current } from "lib/use-current"
import Convention from "models/convention-api/v1/Convention"

const getConventionLocationStyleLabel = (convention: Convention, current: Current) => {
  const expoCopyText = getExpoCopyText(current)
  return `${convention.automatically_generated ? "" : "Multi "}${ConventionLocationStyleLabel[convention.location_style]} ${expoCopyText.singular}`
}

export default getConventionLocationStyleLabel
