import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import { Button, FormControl } from "@mui/material"
import FormLabel from "@mui/material/FormLabel"
import { makeStyles } from "tss-react/mui-compat"
import moment from "moment-timezone"
import React from "react"

const useStyles = makeStyles()(
  (theme) => ({
    formLabel: {
      ...theme.fonts.lato400,

      color: theme.palette.gray.darkest,
      fontSize: 14,
    },
    button: {
      ...theme.fonts.lato400,

      display: "flex",
      justifyContent: "space-between",

      height: 60,
      padding: 15,
      marginTop: 5,
      borderRadius: 10,

      backgroundColor: theme.palette.gray.light,
      boxShadow: "none",

      color: theme.palette.gray.darker,
      fontSize: 16,

      "&.MuiButton-root": {
        textTransform: "capitalize",
      },
      "&:hover": {
        boxShadow: "none",
        backgroundColor: theme.palette.gray.lighter,
      },
    },
    buttonDate: {
      color: theme.palette.gray.darkest,
    },
    icon: {
      color: theme.palette.gray.darkest,
    },
  })
)

interface DatePickerTriggerButtonProps {
  onClick: () => void
  date: moment.Moment | null
  label: string
}

export const DatePickerTriggerButton = React.forwardRef<
  HTMLButtonElement,
  DatePickerTriggerButtonProps
>(function DatePickerTriggerButtonProps(props, ref) {
  const { onClick, date, label } = props
  const { classes, cx } = useStyles()

  const hasDate = date !== null
  const buttonText = hasDate
    ? // Output: Jan 11, 2021
      date.format("MMM D, YYYY")
    : "Select Date"

  return (
    <FormControl fullWidth>
      <FormLabel className={classes.formLabel}>{label}</FormLabel>
      <Button
        fullWidth
        variant="contained"
        className={cx(classes.button, hasDate && classes.buttonDate)}
        aria-label={hasDate ? `${buttonText} selected` : buttonText}
        ref={ref}
        onClick={onClick}
        endIcon={<CalendarTodayIcon className={classes.icon} />}>
        {buttonText}
      </Button>
    </FormControl>
  )
})
