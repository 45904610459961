import { styled, Typography } from "@mui/material"
import Convention from "models/convention-api/v1/Convention"
import Link from "next/link"
import React from "react"

interface ConventionCardNameProps {
  convention: Convention
  href: string
}

const ConventionCardName = (props: ConventionCardNameProps) => {
  const { convention, href } = props

  return (
    <Link href={href} passHref>
      <CustomTypography role="heading">
        {convention.name}
      </CustomTypography>
    </Link>
  )
}

export default ConventionCardName

const CustomTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.header2,
  margin: 0,
  textTransform: "capitalize",
  cursor: "pointer",
  color: theme.palette.gray.darkest,
})) as typeof Typography
