import { Box, Button, styled, TextField, Typography } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import { ConventionLocationStyle } from "app-constants/constants"
import { DatePicker } from "components/common/DatePicker"
import useConventionFilters from "hooks/conventions/useConventionFilters"
import useBoundDebounceState from "hooks/useBoundDebounceState"
import React from "react"
import { pxToRem } from "utils/helpers"

interface ConventionsFilterPanelProps {
  filters: ReturnType<typeof useConventionFilters>
}

export const CONVENTIONS_FILTER_PANEL_HEIGHT = pxToRem(1120)

const ConventionsFilterPanel = (props: ConventionsFilterPanelProps) => {
  const { filters } = props

  // need this to improve typing performance
  const [name, setName] = useBoundDebounceState<string>({
    state: filters.name,
    setState: filters.setName,
  })

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault()
  }

  return (
    <_Container component="form" onSubmit={handleSubmit}>
      <_HeaderContainer>
        <Typography component="h3" variant="header3">
          Filters
        </Typography>
      </_HeaderContainer>
      <_ActionsContainer>
        {filters.isFilterApplied && (
          <Button
            sx={{ textTransform: "capitalize" }}
            fullWidth
            color="dark"
            variant="text"
            onClick={filters.reset}>
            Clear Filters
          </Button>
        )}
      </_ActionsContainer>
      <_ContentContainer>
        <_SectionContainer>
          <Typography variant="header4" component="h4">
            Search
          </Typography>
          <_SearchTextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            variant="filled"
            placeholder="Search by event name"
          />
        </_SectionContainer>
        <_SectionContainer>
          <Typography variant="header4" component="h4">
            Pick a date range
          </Typography>
          <_DatePickersContainer>
            <DatePicker
              selectedDate={filters.startAt}
              onChange={filters.setStartAt}
              label="Between"
              maxDate={filters.endAt ?? undefined}
            />
            <DatePicker
              selectedDate={filters.endAt}
              onChange={filters.setEndAt}
              label="And"
              minDate={filters.startAt ?? undefined}
            />
          </_DatePickersContainer>
        </_SectionContainer>
        <_SectionContainer>
          <FormControl component="fieldset">
            <Typography variant="header4" component="legend" marginBottom={pxToRem(15)}>
              Event Type
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={filters.locationStyles.includes(ConventionLocationStyle.INPERSON)}
                    onChange={() => filters.toggleLocationStyle(ConventionLocationStyle.INPERSON)}
                  />
                }
                label="In Person Events"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={filters.locationStyles.includes(ConventionLocationStyle.VIRTUAL)}
                    onChange={() => filters.toggleLocationStyle(ConventionLocationStyle.VIRTUAL)}
                  />
                }
                label="Virtual Events"
              />
            </FormGroup>
          </FormControl>
        </_SectionContainer>
      </_ContentContainer>
    </_Container>
  )
}

export default ConventionsFilterPanel

const _Container = styled(Box)(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(30),

  minWidth: pxToRem(300),
  maxHeight: CONVENTIONS_FILTER_PANEL_HEIGHT,
  overflow: "hidden",
})) as typeof Box

const _ContentContainer = styled(Box)(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  height: "100%",
  gap: pxToRem(30),
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
  },
})) as typeof Box

const _SectionContainer = styled(Box)(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(15),
})) as typeof Box

const _HeaderContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexFlow: "nowrap row",
}) as typeof Box

const _SearchTextField = styled(TextField)(({}) => ({
  height: pxToRem(60),
  ".MuiFilledInput-input": {
    padding: `${pxToRem(19)} ${pxToRem(15)}`,
  },
})) as typeof TextField

const _DatePickersContainer = styled(Box)(({}) => ({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(20),
})) as typeof Box

const _ActionsContainer = styled(Box)(({}) => ({
  display: "flex",
  flexFlow: "column nowrap",
  gap: pxToRem(20),
  minHeight: pxToRem(32),
  marginTop: pxToRem(-10),
  marginBottom: pxToRem(-10),
})) as typeof Box
